<div *ngIf="utils.loading" class="transparent-loading">
  <mat-spinner mode="indeterminate" class="creating-booking"></mat-spinner>
</div>
<nav class="navbar navbar-north navbar-expand-lg navbar-dark bg-dark py-0">
    <div class="container">
        <a href="#" class="navbar-brand py-0">
            <img src="/assets/img/lobos-ferry-corralejo-m.jpg" class="navbar-logo" alt="naviera nortour">
        </a>
        <button (click)="toogleNavBar()" class="navbar-toggler mr-0" type="button" aria-controls="navbarsExample07" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbar">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a target="blank_" class="nav-link px-4" href="https://navieranortour.com/" i18n="StartMenu">Inicio <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a target="blank_" class="nav-link px-4" href="https://navieranortour.com/actividades-fuerteventura/" i18n="FerryMenu">Ferry y actividades</a>
                </li>
                
                <li class="nav-item">
                    <a target="blank_" class="nav-link px-4" href="https://navieranortour.com/barco-isla-de-lobos-fuerteventura/" i18n="WolfMenu">Isla de Lobos</a>
                </li>
                <li class="nav-item">
                    <a target="blank_" class="nav-link px-4" href="https://navieranortour.com/ferry/barco-a-isla-lobos/#taquillas" i18n="LocationMenu">¿Cómo llegar?</a>
                </li>
                <li class="nav-item">
                    <a target="blank_" class="nav-link px-4" href="https://navieranortour.com/category/blog/" i18n="BlogMenu">Blog</a>
                </li>
                <li class="nav-item featured">
                    <a target="blank_" class="nav-link nav-link btn btn-outline-light" href="https://booking.navieranortour.com/booking-form" i18n="TicketMenu">Tickets Online</a>
                </li>
            </ul>
            <ul class="navbar-nav my-2 my-md-0" (click)="toogleDropdown()">
                <li class="nav-item dropdown">
                    <a *ngIf="lang.includes('pl')" class="nav-link dropdown-toggle" href="#" aria-haspopup="true" aria-expanded="false"><img src="/assets/img/pl.png" alt="language flag"></a>
                    <a *ngIf="lang.includes('fr')" class="nav-link dropdown-toggle" href="#" aria-haspopup="true" aria-expanded="false"><img src="/assets/img/fr.png" alt="language flag"></a>
                    <a *ngIf="lang.includes('it')" class="nav-link dropdown-toggle" href="#" aria-haspopup="true" aria-expanded="false"><img src="/assets/img/it.png" alt="language flag"></a>
                    <a *ngIf="lang.includes('de')" class="nav-link dropdown-toggle" href="#" aria-haspopup="true" aria-expanded="false"><img src="/assets/img/de.png" alt="language flag"></a>
                    <a *ngIf="lang.includes('en')" class="nav-link dropdown-toggle" href="#" aria-haspopup="true" aria-expanded="false"><img src="/assets/img/en.png" alt="language flag"></a>
                    <a *ngIf="lang.includes('es')" class="nav-link dropdown-toggle" href="#" aria-haspopup="true" aria-expanded="false"><img src="/assets/img/es.png" alt="language flag"></a>
                    <div class="dropdown-menu bg-dark" aria-labelledby="dropdown01" id="dropdown">
                        <a *ngIf="!lang.includes('pl')" class="dropdown-item" href="https://desfront-nortour.tecnicascompetitivas.com/pl/"><img src="/assets/img/pl.png" alt="language flag"></a>
                        <a *ngIf="!lang.includes('fr')" class="dropdown-item" href="https://desfront-nortour.tecnicascompetitivas.com/fr/"><img src="/assets/img/fr.png" alt="language flag"></a>
                        <a *ngIf="!lang.includes('it')" class="dropdown-item" href="https://desfront-nortour.tecnicascompetitivas.com/it/"><img src="/assets/img/it.png" alt="language flag"></a>
                        <a *ngIf="!lang.includes('de')" class="dropdown-item" href="https://desfront-nortour.tecnicascompetitivas.com/de/"><img src="/assets/img/de.png" alt="language flag"></a>
                        <a *ngIf="!lang.includes('en')" class="dropdown-item" href="https://desfront-nortour.tecnicascompetitivas.com/en/"><img src="/assets/img/en.png" alt="language flag"></a>
                        <a *ngIf="!lang.includes('es')" class="dropdown-item" href="https://desfront-nortour.tecnicascompetitivas.com/#/"><img src="/assets/img/es.png" alt="language flag"></a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
<div class="container content">
  <router-outlet></router-outlet>
</div>

<footer id="footer" class="pt-4 colophon back-footer" style="background-color: #242424;">
    <div id="footer_content">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-3 pt-4 pb-1 py-md-5 order-2 order-md-1">
                          <img class="img-fluid" style="width: 218px"  src="/assets/img/FEDER.jpg" alt="FEDER">
                        </div>
                        <div class="col-12 col-md-3 pt-4 pb-1 py-md-5 order-2 order-md-1">
                            <img class="img-fluid" style="width: 218px" src="/assets/img/logo_majorero.svg" onerror="this.onerror=null; this.src='/img/logo_majorero.png'" alt="Majorero logo">
                        </div>
                        <div class="col-12 col-md-auto order-1 order-md-2">
                            <img class="img-fluid" src="/assets/img/logo-full.svg" onerror="this.onerror=null; this.src='/img/logo-full.png'" alt="naviera nortour logo">
                        </div>
                        <div class="col-12 col-md-3 pt-4 pb-1 py-md-5 order-2">
                            <img class="img-fluid" style="width: 230px" src="/assets/img/sicted_logo_bilingue_bn.png" onerror="this.onerror=null; this.src='/img/sicted_logo_bilingue_bn.png'" alt="sicted logo">
                        </div>
                    </div>
                    <p class="mt-3 mb-2">Nortour S.L. - CIF: B-35139005 - C/Bajo Amarillo, 28. 35660. Corralejo, España.</p>
                </div>
            </div>
        </div>
    </div>

    <div id="subfooter_content">
        <div class="container">

            <ul id="nav-footer" class="menu mt-3 pt-3">
                                    <li class="menu-item">
                        <a href="https://navieranortour.com/cookies/" i18n="CookiesFooter" target="_blank">
                            Política de Cookies                        </a>
                    </li>
                                <li class="menu-item">
                    <a href="https://navieranortour.com/privacidad/" i18n="PrivacyFooter" target="_blank">
                        Política de Privacidad                    </a>
                </li>
                <li class="menu-item">
                    <a href="https://navieranortour.com/cancelacion/" i18n="CancelationFooter" target="_blank">
                        Política de Cancelación                    </a>
                </li>
                                    <li class="menu-item">
                        <a href="https://navieranortour.com/aviso-legal/" i18n="LegalFooter" target="_blank">
                            Aviso Legal                        </a>
                    </li>
                                <li class="menu-item">
                    <a href="https://navieranortour.com/terminos-transporte/" i18n="TermsFooter" target="_blank">
                        Términos Generales de Transporte                    </a>
                </li>
                <li class="menu-item">
                    <a href="https://navieranortour.com/preguntas-frecuentes/" i18n="FaqFooter" target="_blank">
                        Faqs
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div id="creditos">
        <div class="container">

            <div class="row">
                <div class="col-md-4 text-white">
                    <p class="text-white my-2" i18n="ReserveFooter">
                        © 2021 Naviera Nortour | Derechos reservados
                    </p>
                </div>

                <div class="col-md-8">
                    <p class="text-white text-right mb-0">
                        <a href="https://booking.navieranortour.com/secure-pay" i18n="SecurePaymentFooter">PAGO SEGURO 100%</a>
                        <img src="/assets/img/ssl.png" alt="ssl" class="d-inline-block mx-1">
                        <img src="/assets/img/visa.png" alt="visa" class="d-inline-block mx-1">
                        <img src="/assets/img/mastercard.png" alt="mastercard" class="d-inline-block mx-1">
                        <img src="/assets/img/americanexpress.png" alt="american express" class="d-inline-block mx-1">
                    </p>
                </div>
            </div>

        </div>
    </div>
</footer>


