
<mat-grid-list rowHeight="80px" gutterSize="10px" [cols]=colSize (window:resize)="onResize($event)">


  <mat-grid-tile>
    <mat-form-field appearance="outline">
      <mat-label i18n="Experience">Experiencia</mat-label>
      <mat-select [(ngModel)]="selectedPaquete" (selectionChange)="clearInfo(); obtenerSuplementosPaquete();">
        <mat-option *ngFor="let paquete of listaPaquetes" [value]="paquete">{{paquete.paqNombreTraduccion}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-grid-tile>


  <mat-grid-tile>
    <mat-form-field appearance="outline">
      <mat-label i18n="Date">Fecha</mat-label>
      <input autocomplete="off" style="color: unset" matInput [min]="todayNoTime" [matDatepicker]="picker" (click)="picker.open()" [value]="selectedFecha" (dateChange)="clearInfo(); getHorarioCalendario($event.target.value)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #picker></mat-datepicker>
    </mat-form-field>
  </mat-grid-tile>


  <mat-grid-tile>
    <mat-form-field appearance="outline">
      <mat-label i18n="Departure">Salida</mat-label>
      <mat-select [(ngModel)]="selectedHorarioIda" (selectionChange)="filterVueltaGreaterThanIda(); checkServicioAvailable()">
        <mat-option [disabled]="true" *ngIf="!availableHorariosIda || availableHorariosIda?.length === 0 || diaIncompatible">Sin Datos</mat-option>
        <ng-container *ngIf="!diaIncompatible">
          <mat-option *ngFor="let horarioIda of availableHorariosIda" [value]="horarioIda">{{horarioIda?.horFechaInicio | hourPipe }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </mat-grid-tile>


  <mat-grid-tile>
    <mat-form-field appearance="outline">
      <mat-label i18n="Arrival">Vuelta</mat-label>
      <mat-select [(ngModel)]="selectedHorarioVuelta" (selectionChange)="checkServicioAvailable()">
        <mat-option [disabled]="true" *ngIf="!availableHorariosVuelta || availableHorariosVuelta?.length === 0 || diaIncompatible || !selectedHorarioIda" i18n="NoData">Sin Datos</mat-option>
        <ng-container *ngIf="!diaIncompatible && selectedHorarioIda">
          <mat-option *ngFor="let horarioVuelta of availableHorariosVuelta" [value]="horarioVuelta">{{horarioVuelta?.horFechaInicio | hourPipe }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </mat-grid-tile>
</mat-grid-list>

<!-- TODO: TENER EN CUENTA UNIDADES -->
<mat-card *ngIf="selectedPaquete && selectedFecha && selectedHorarioIda && selectedHorarioVuelta">
  <mat-card *ngIf="selectedPaquete.paqRequiereAutorizacion">
    <p>{{selectedPaquete.paqAutorizacionMensaje}}</p>
    <mat-radio-group aria-label="Elija una opción" [(ngModel)]="radioValue">
      <mat-radio-button value=1 (change)="toogleSelectAutorizacion(true)"><b i18n="Si">SÍ</b>, {{selectedPaquete.paqAutorizacionSi}}</mat-radio-button>
      <mat-radio-button value=2 (change)="toogleSelectAutorizacion(false)"><b i18n="No">NO</b>, {{selectedPaquete.paqAutorizacionNo}}</mat-radio-button>
    </mat-radio-group>
  </mat-card><br>
  <ng-container *ngIf="!selectedPaquete.paqRequiereAutorizacion || autorizacionChecked">
    <div class="row">
      <div class="column left">
        <app-selectPasajeros (updateTarifas)="updateTarifas($event)" (updateSuplementos)="updateSuplementos($event)" [paquete]="selectedPaquete" [listaSuplementos]="listaSuplementos"></app-selectPasajeros>
      </div>
      <div class="column right">
        <app-resumenPago (eventComprarTickets)="comprarTickets($event)" [paquete]="selectedPaquete" [autorizacion]="autorizacion" [quiereGestionAutorizacion]="quiereGestionAutorizacion"></app-resumenPago>
      </div>
    </div>
  </ng-container>

</mat-card>
